<template>
	<div>
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Data for {{user_instance.address_string}}
		</h1>
		<field-value-input-fields
			class='settings-wrapper'
			v-for="field in input_data_fields"
			:key="field.id"
			:field="field"
		/>
		<data-field-value-select-fields
			class='settings-wrapper'
			v-for="(field, index) in select_data_fields"
			:key="field.id"
			:field="field"
			:index="index"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import FieldValueInputFields from '@/components/users/edit/data/FieldValueInputFields'
import DataFieldValueSelectFields from '@/components/users/edit/data/DataFieldValueSelectFields'


export default {
	name: 'Data',
	components: { FieldValueInputFields, DataFieldValueSelectFields },
	computed: {
		...mapGetters(['select_data_fields', 'input_data_fields', 'user_instance']),
	},
}
</script>
