<template>
	<div class="flex column justify-between items-start">
		<div class="full-width">
			<q-field
				v-if="field.field_type.includes('PHONE')"
				stack-label
				borderless
				:label="field.label"
				:error-message="data_field_error_messages[field.label]"
				:error="has_error"
				indicator-color="primary"
				active-color="primary"
			>
				<vue-tel-input
					v-model="field_value"
					v-bind="bindProps"
					class="full-width q-pa-sm q-mt-sm"
					:validCharactersOnly="true"
					:class="$q.screen.lt.sm ? 'small-view' : ''"
				>
				</vue-tel-input>
			</q-field>
			<q-input
				v-else-if="field.field_type === 'NUMERIC'"
				outlined
				v-model="field_value"
				:label="field.label"
				:name="field.label"
				:hint="hint_text"
				type="number"
				:error-message="data_field_error_messages[field.label]"
				:error="has_error"
				onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
			/>
			<q-input
				v-else
				outlined
				v-model="field_value"
				:label="field.label"
				:name="field.label"
				:hint="hint_text"
				type="text"
				:error-message="data_field_error_messages[field.label]"
				:error="has_error"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'DataFieldValueInputFields',
	props: {
		field: Object,
	},
	data(){
		return {
			field_value: "",
			position: null,
			bindProps: {
				mode: "international",
				defaultCountry: "GB",
				placeholder: "Enter a phone number"
			},
		}
	},
	computed: {
		...mapGetters(['user_field_values', 'data_field_error_messages']),
		hint_text(){
			let hint = ""
			if (this.field.field_type == 'EMAIL'){
				hint = "Enter a valid email address"
			} else if (this.field.field_type == "TEXT") {
				hint = "Enter an alpha numeric value in this field (special characters supported)"
			} else if (this.field.field_type == 'NUMERIC'){
				hint = "Enter a numeric value in this field"
			} else {
				hint = `Enter a valid ${this.field.field_type.toLowerCase()}`
			}
			return hint
		},
		has_error() {
			if (this.data_field_error_messages[this.field.label]) {
				return true
			} else {
				return false
			}
		}
	},
	methods: {
		...mapActions(['updateInputFieldValues', 'createFieldValueInstance']),
	},
	async created(){
		for (let [index, val] of this.user_field_values.entries()){
			if (val.field == this.field.id){
				this.field_value = val.values
				this.position = index
				break
			}
		}
		if(this.position == null){
			this.position = await this.createFieldValueInstance({'field': this.field})
		}
	},
	watch: {
		'field_value': function (){
			this.updateInputFieldValues({'value': this.field_value, 'position': this.position})
		}
	}
}
</script>

<style>


.vue-tel-input input {
	background-color: #fafafa;
}

.vue-tel-input:focus-within{
	border-color: var(--primary-color);
	border-width: 2px;
	box-shadow: inset 0 0 0
}

.small-view .vti__dropdown .vti__dropdown-list{
	width: auto!important;
}

</style>
