<template>
	<div class="flex column justify-between items-start">
		<div class="full-width">
			<q-select
				v-model="selected_option"
				:multiple="field.field_type == 'MULTISELECT'"
				:clearable="field.field_type == 'SINGLESELECT'"
				:options="field_options"
				:label="field.label"
				:hint="field.field_type == 'MULTISELECT' ? 'Select 1 or more options': 'Select 1 option'"
				:error-message="data_field_error_messages[field.label]"
				:error="has_error"
			/>

		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'DataFieldValueSelectFields',
	props: {
		field: Object,
	},
	data() {
		return {
			field_options: [],
			selected_option: null,
			position: null
		}
	},
	computed: {
		...mapGetters(['cg_fields', 'user_field_values', 'data_field_error_messages']),
		has_error() {
			if (this.data_field_error_messages[this.field.label]) {
				return true
			} else {
				return false
			}
		}
	},
	methods: {
		...mapActions(['updateSelectFieldValues', 'createFieldValueInstance']),
		setSelectedOptionAndPosition(){
			let selected_option = {}
			for (let [index, value] of this.user_field_values.entries()){
				if (value.field == this.field.id){
					if (this.field.field_type == 'SINGLESELECT'){
						selected_option = this.field_options.filter(el => el.id.toString() == value.values)[0]
						selected_option = selected_option ? selected_option : ""
					} else if (this.field.field_type == 'MULTISELECT'){
						if (value.values){
							let fv_ids = JSON.parse(value.values).map(el => el.id)
							selected_option = this.field_options.filter(el => fv_ids.includes(el.id))
						} else {
							selected_option = []
						}
					}
					this.position = index
					this.selected_option = selected_option
					break
				}
			}
		}
	},
	async created(){
		for (let option of this.field.options){
			option.label = option.value
			this.field_options.push(option)
		}
		this.field_options = this.field_options.sort((a, b) => a.label.localeCompare(b.label))
		this.setSelectedOptionAndPosition()
		if(this.position === null){
			this.position = await this.createFieldValueInstance({'field': this.field})
		}
	},
	watch: {
		'selected_option': function (){
			this.updateSelectFieldValues({
				'selected_option': this.selected_option,
				'position': this.position,
				'field_type': this.field.field_type
			})
		}
	}
}
</script>
